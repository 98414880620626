import { useState, useEffect, useContext, useLayoutEffect } from "react";
import { OAuth2 } from "components";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Alert, Stack, TextField, useTheme } from "@mui/material";
import { useTranslations, useUser, useForm } from "hooks";
import { getTranslation } from "common";
import { noTokenApi, usersApi } from "api";
import { isEmail, isRequired, Validation } from "hooks/useForm";
import FlowentiLogo from "../image/Logo.svg"; //# use this cause is without background
import { removeSelectedOrganization } from "Redux/organizationReducer";
import { AuthContext } from "contexts";
import { LoadingButton } from "@mui/lab";
import LoginImage from "../image/login.png";
import AuthFlowLayout from "components/layouts/AuthFlowLayout";
import { noSpecialChars } from "common/utilities";

const Login = () => {
  const [searchParams] = useSearchParams();
  const { fromRegister } = useLocation().state || {};
  const [loading, setLoading] = useState<boolean>(false);
  const emailParam = searchParams.get("email");
  const translations = useTranslations();
  const user = useUser();
  const {
    actions: { setJwt, setUser, setRegisterUser },
  } = useContext(AuthContext);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [exist, setExist] = useState<boolean>(false);

  useEffect(() => {
    if (!user) {
      dispatch(removeSelectedOrganization(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    (async () => {
      if (emailParam) {
        const response = await usersApi.get(`emailinplatform/${emailParam.toLowerCase()}`);
        setExist(response.data.mailexist);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validations: Validation[] = [
    ({ email }) =>
      isRequired(email) || {
        email: getTranslation(translations, "register.valid.Error_after_at"),
      },
    ({ captcha }) =>
      isRequired(captcha) ||
      process.env.NODE_ENV === "development" ||
      Boolean(window.Cypress) || {
        captcha: getTranslation(translations, "login.valid.captchaError"),
      },
    ({ email }) =>
      isEmail(email) || {
        email: getTranslation(translations, "register.valid.Error_after_at"),
      },
    ({ email }) =>
      noSpecialChars(email) || {
        email: getTranslation(translations, "register.valid.Error_invalid_chars"),
      },
  ];

  const { values, changeHandler, errors, touched, isValid } = useForm(
    {
      email: emailParam ?? "",
      password: "",
      captcha: null,
    },
    validations
  );
  const enterWithGoogle = async (token: string, registrated: boolean) => {
    try {
      setJwt(token);
      // const decoded: JWTData = jwtDecode(response.data.token);
      // const id = decoded.userid;
      let result = await usersApi.get(`${token}`);
      if (registrated) {
        setUser({
          jwt: token,
          userId: result.data.user.id,
          email: result.data.user.email,
          username: result.data.user.username,
          firstname: result.data.user.firstname,
          lastname: result.data.user.lastname,
          picture: result.data.user.picture,
          linkprofile: result.data.user.linkprofile,
          subscriptiontype: result.data.user.subscriptiontype,
          i18n: result.data.user.i18n,
          dob: result.data.user.dob,
          authenticationtype: result.data.user.authenticationtype,
        });
        navigate("/dashboard/organizations");
      } else {
        setRegisterUser({
          userId: result.data.user.id,
          jwt: token,
          ...result.data.user,
        });
        navigate("/organization");
      }
    } catch {
    } finally {
    }
  };

  const [autofilled, setAutofilled] = useState<boolean | undefined>();

  useEffect(() => {
    if (autofilled && (touched.email || touched.password || touched.captcha)) {
      setAutofilled(undefined);
    }
  }, [touched, autofilled]);

  useLayoutEffect(() => {
    /**
     * The field can be prefilled on the very first page loading by the browser
     * By the security reason browser limits access to the field value from JS level and the value becomes available
     * only after first user interaction with the page
     * So, even if the Formik thinks that the field is not touched by user and empty,
     * it actually can have some value, so we should process this edge case in the form logic
     */
    const checkAutofilled = () => {
      const autofilled = !!document
        .getElementById("password-login")
        ?.matches("*:-webkit-autofill");
      if (autofilled) {
        setAutofilled(true);
      }
    };
    // The time when it's ready is not very stable, so check few times
    setTimeout(checkAutofilled, 500);
    setTimeout(checkAutofilled, 1000);
    setTimeout(checkAutofilled, 2000);
  }, []);
  const submitData = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (isValid) {
      event.preventDefault();
      setLoading(true);
      const { email, password, captcha } = values;
      if (!captcha && process.env.NODE_ENV === "production" && !window.Cypress)
        return;
      try {
        const response = await noTokenApi.post("login", {
          jwtusername: email.toLowerCase(),
          jwtpassword: password,
        });
        setJwt(response.data.token);
        // const decoded: JWTData = jwtDecode(response.data.token);
        // const id = decoded.userid;
        let result = await usersApi.get(`${response.data.token}`);
        setUser({
          jwt: response.data.token,
          userId: result.data.user.id,
          email: result.data.user.email,
          username: result.data.user.username,
          firstname: result.data.user.firstname,
          lastname: result.data.user.lastname,
          picture: result.data.user.picture,
          linkprofile: result.data.user.linkprofile,
          subscriptiontype: result.data.user.subscriptiontype,
          i18n: result.data.user.i18n,
          dob: result.data.user.dob,
          authenticationtype: result.data.user.authenticationtype,
        });
        navigate("/dashboard/organizations");
      } catch {
      } finally {
      }
      setLoading(false);
    }
  };

  return (
    <AuthFlowLayout
      title="Flowenti"
      image={FlowentiLogo}
      rightAltImage="no stat alt"
      rightImage={LoginImage}
      cardTitle={getTranslation(translations, "register.signin.label")}
      alt="Logo alt"
    >
      <Stack spacing={2}>
        {exist && (
          <Alert variant="outlined" color="info">
            {getTranslation(
              translations,
              "register.label.email_is_present_on_platform"
            )}
          </Alert>
        )}
        {fromRegister && (
          <Alert variant="outlined" color="info">
            {getTranslation(
              translations,
              "register.label.checkyouremail"
            )}
          </Alert>
        )}
        <Stack
          sx={{ mt: (theme) => `${theme.spacing(4)}!important` }}
          component="form"
          spacing={2}
        >
          <TextField
            id="email-login"
            size="small"
            label={getTranslation(translations, "user.email.label")}
            type="email"
            InputLabelProps={{ shrink: autofilled }}
            autoComplete="off"
            fullWidth
            value={values.email}
            helperText={touched.email && errors.email}
            error={Boolean(touched.email && errors.email)}
            onChange={({ target }) => {
              if (target.value === "errbit") {
                setExist(() => {
                  throw Error("TEST ERRBIT ERROR");
                });
              }
              changeHandler("email", target.value);
            }}
          />
          <TextField
            id="password-login"
            size="small"
            label="Password"
            type="password"
            InputLabelProps={{ shrink: autofilled }}
            autoComplete="off"
            fullWidth
            value={values.password}
            helperText={touched.password && errors.password}
            error={Boolean(touched.password && errors.password)}
            onChange={({ target }) => changeHandler("password", target.value)}
          />
          <Stack mt={2} direction="row" justifyContent="center">
            {process.env.NODE_ENV === "production" && !window.Cypress && (
              <ReCAPTCHA
                id="recaptcha"
                theme={theme.palette.mode}
                onChange={(_: any) => changeHandler("captcha", _)}
                sitekey={"6LeTBVspAAAAAPds-GYTsoJaZJ3ean2pXKNNX2A6"}
              />
            )}
          </Stack>
          <Stack mt={2} direction="row">
            <LoadingButton
              id="button-login"
              color="primary"
              loading={loading}
              fullWidth
              size="small"
              // disabled={!isValid}
              variant="outlined"
              type="submit"
              onClick={submitData}
            >
              {getTranslation(translations, "register.signin.label")}
            </LoadingButton>
          </Stack>
        </Stack>
        <OAuth2 login onGoogleConfirm={enterWithGoogle} />
      </Stack>
    </AuthFlowLayout>
  );
};

export default Login;
